 const e = {
"BASE_URL": "https://modulator-api.sevenpointinteriors.com",
"HOSTNAME": "sevenpointconfigurator-j2n5z",
"AUTH0_URI": "https://modulator.sevenpointinteriors.com/admin",
"APP_PLATFORM_INDEX_DOCUMENT": "index.html",
"PWD": "/workspace/fe",
"CNB_STACK_ID": "heroku-22",
"CNB_BUILDPACK_DIR": "/cnb/buildpacks/digitalocean_custom/0.1.3",
"REPORTING_URL": "https://oyster-app-haxup.ondigitalocean.app",
"BUILD_COMMAND": "cd fe; node bin/env_to_js.js > src/env.js; yarn build",
"HOME": "/home/apps",
"DD_ON": "1",
"APP_PLATFORM_COMPONENT_TYPE": "static_site",
"LIBRARY_PATH": "/layers/heroku_nodejs-engine/yarn/lib:/layers/heroku_nodejs-engine/nodejs/lib",
"SHLVL": "1",
"LD_LIBRARY_PATH": "/layers/heroku_nodejs-engine/yarn/lib:/layers/heroku_nodejs-engine/nodejs/lib",
"STACK": "heroku-22",
"PATH": "/layers/heroku_nodejs-engine/yarn/bin:/layers/heroku_nodejs-engine/toolbox/bin:/layers/heroku_nodejs-engine/nodejs/bin:/opt/apps-go/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin",
"CPATH": "/layers/heroku_nodejs-engine/nodejs/include",
"OLDPWD": "/workspace",
"_": "/layers/heroku_nodejs-engine/nodejs/bin/node",
}

export default e
