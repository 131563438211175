import React, { useState, useEffect, useRef, useMemo } from "react"
import { StateProvider } from "./state/index"
import "./css/fluentGlobal.css"
import { ThemeProvider } from "@fluentui/react/lib/Theme"
import { mergeStyles, getTheme, AnimationClassNames } from "@fluentui/react"

import { initializeIcons } from "@fluentui/font-icons-mdl2"
import AuthMain from "./components/AuthMain"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import PublicMain from "./components/PublicMain"

initializeIcons()

function AppWithTheme() {
    const myTheme = {
        palette: {
            themePrimary: '#d95a24',
            themeLighterAlt: '#fdf8f5',
            themeLighter: '#f9e2d8',
            themeLight: '#f4c9b7',
            themeTertiary: '#e89774',
            themeSecondary: '#dd6c3c',
            themeDarkAlt: '#c35221',
            themeDark: '#a5451c',
            themeDarker: '#793315',
            neutralLighterAlt: '#faf9f8',
            neutralLighter: '#f3f2f1',
            neutralLight: '#edebe9',
            neutralQuaternaryAlt: '#e1dfdd',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c6c4',
            neutralTertiary: '#595959',
            neutralSecondary: '#373737',
            neutralPrimaryAlt: '#2f2f2f',
            neutralPrimary: '#000000',
            neutralDark: '#151515',
            black: '#0b0b0b',
            white: '#ffffff',
        },
        defaultFontStyle: {
            fontFamily: "regular",
            fontSize: "11pt",
        },
    }
    return (
        <ThemeProvider
            theme={myTheme}
            applyTo="body"
            style={{ height: "100%", width: "100%" }}
        >
            <BrowserRouter>
                <Switch>
                    <Route path="/admin">
                        <AuthMain />
                    </Route>
                    <Route>
                        <PublicMain />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

function App() {
    return (
        <StateProvider>
            <AppWithTheme />
        </StateProvider>
    )
}

export default App
